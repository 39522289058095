import * as React from 'react';
import styled, { css } from 'styled-components';

import { media } from 'components/_settings/variables';

export const GridCell = styled.div``;

function buildCss({ columns, gap }) {
  let gapX = gap;
  let gapY = gap;
  if (gap instanceof Array) {
    gapX = gap[0];
    gapY = gap[1];
  }

  return `
    ${gap !== undefined ? `margin: -${gapY / 2}px -${gapX / 2}px;` : ''}
    > ${GridCell} {
      ${columns ? `width: ${100 / columns}%;` : columns === null ? 'width: auto;' : ''}
      ${gap !== undefined ? `padding: ${gapY / 2}px ${gapX / 2}px;` : ''}
    }
  `;
}

function createMediaCss(mediaConfig) {
  let result = '';
  for (const key of Object.keys(mediaConfig)) {
    result += `${media[key]} { ${buildCss(mediaConfig[key])} }`;
  }
  return result;
}

export const Items = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${(p) => css`
    ${buildCss(p)}
    ${p.media && createMediaCss(p.media)}
  `}
`;

Items.defaultProps = {
  columns: null,
  gap: [30, 15],
};

export const Wrapper = styled.div``;

export const Grid = styled(({ className, ...rest }) => (
  <Wrapper className={className}>
    <Items {...rest} />
  </Wrapper>
))``;
